import React, { useEffect } from 'react'
import { fetchNebula, nebularEvent, setNebula, validKey, socket } from './nebular';


const Chat = () => {

  const send = (e) => {
    if (e.keyCode === 13) {
      let storage = fetchNebula()

      let avatar = localStorage.user

      var newMessage = {
        profile: avatar,
        msg: e.target.value
      }


      if (storage[localStorage.room]) {
        storage[localStorage.room].push(newMessage)
      } else {
        storage[localStorage.room] = [];
        storage[localStorage.room].push(newMessage)
      }

      setNebula(storage)



      nebularEvent("msgUpdate", {})

      e.target.value = "";
    }

  }

  const leaveRoom = () => {
    localStorage.clear()
    window.location.reload();
  }

  const updatethread = () => {
    document.getElementById('ct').innerHTML = "";

    let cd = fetchNebula()

    if (cd[localStorage.room]) {
      cd[localStorage.room].forEach(item => {
        document.getElementById('ct').innerHTML += `
      <div class="chat-section">
      <img src="${localStorage.user}" class="avatar" />
      <div class="message">
      ${item.msg}
     </div>
    </div>
      `
      });
    }
  }

  socket.on("nebularEvent", (data) => {
    if (validKey(data.uid)) {
      if (data.name === "msgUpdate") {
        updatethread()
      }
    }
  })


  useEffect(() => {
    updatethread()
  }, [])

  return (
    <div className="chat">
      <span className="ml-3">Room Key: {localStorage.room}</span>
      <button className="btn btn-dark m-3" onClick={leaveRoom}> Leave Room</button>
      <div className="chat-thread" id="ct">


      </div>
      <div className="">
        <input onKeyUp={e => { send(e) }} placeholder="Type message and press enter" />
      </div>
    </div>
  )
}

export default Chat;