import React, { useEffect } from 'react'
import swal from 'sweetalert2';
import { guid } from './nebular';

const Login = (props) => {



  const joinRoom = () => {
    swal.fire({
      title: 'Join Room',
      text: 'Input key to join room',
      input: "text"
    })
      .then(res => {
        if (res.value) {
          localStorage.room = res.value
          window.location.reload()
        }
      })
  }

  const createRoom = () => {
    let roomID = guid();
    swal.fire({
      title: 'Create Room',
      html: `<b>${roomID}</b><br> Copy the link above and share with people you want to talk to, then click ok to proceed to room`,
    })
      .then(res => {
        if (res.value) {
          localStorage.room = roomID;
          window.location.reload()
        }
      })
  }

  function getRandomArbitrary(min, max) {
    return (Math.random() * (max - min) + min).toFixed();
  }

  useEffect(() => {
    localStorage.user = `./assets/p${getRandomArbitrary(1, 6)}.png`
  }, [])

  return (
    <div className="login">
      <div className="login-container">
        <h1 className="logo">Alto</h1>
        <p className="text-muted">Open, Fast, Simple Internet Chat.</p>


        <div className="buttons">
          <button className="btn btn-dark" onClick={createRoom}>
            Create Room
      </button>

          <button className="btn btn-outline-dark" onClick={joinRoom}>
            Join Room
      </button>
        </div>
      </div>
    </div>
  )
}

export default Login;