import React, { useEffect } from 'react';
import Login from './components/Login';
import './styles/index.scss'
import { nebularInit } from './components/nebular'
import Chat from './components/Chat';

function App() {

  useEffect(() => {

    nebularInit('12312312313123123123123123')

  }, [])

  return (
    <div className="App">

      {!localStorage.room ? (
        <Login />
      ) : (
          <Chat />
        )}


    </div>
  );
}

export default App;
